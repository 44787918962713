<template>
  <!-- 
    画面: プラン新規登録
    用途: プランを新規登録する
   -->
  <div>

    <!-- #region Main -->
    <CRow>
      <CCol
        md="12"
        lg="12"
      >
        <CCard>
          <CCardHeader>
            <strong class="card-title">{{ $t("planCreatePage.pageTitle") }}</strong>
          </CCardHeader>

          <CCardBody>
            <CForm class="submit_form">

              <!-- #region プランタイプ -->
              <CRow>
                <CCol class="label"><strong class="required-mark">{{ $t("planCreatePage.planType") }}</strong></CCol>
                <CCol>
                  <CSelect
                    :value.sync="edit.planType"
                    :options="PLAN_TYPE_SELECTION"
                    :description="$t('planCreatePage.planTypeDescription')"
                  />
                  <p
                    class="text-danger edit-info"
                    v-if="$v.edit.planType.$dirty && !$v.edit.planType.required"
                  >
                    {{ $t("validations.required", { vName: $t("planCreatePage.planType") })}}
                  </p>
                </CCol>
              </CRow>
              <!-- #endregion プランタイプ -->

              <!-- #region 名称 -->
              <CRow>
                <CCol class="label"><strong class="required-mark">{{ $t("planCreatePage.name") }}</strong></CCol>
                <CCol>
                  <CInput v-model="edit.name" />
                  <p
                    class="text-danger edit-info"
                    v-if="$v.edit.name.$dirty && !$v.edit.name.required"
                  >
                    {{ $t("validations.required", { vName: $t("planCreatePage.name") })}}
                  </p>
                </CCol>
              </CRow>
              <!-- #endregion 名称 -->

              <!-- #region 確認ボタン -->
              <CButton
                class="px-5 mt-4"
                color="info"
                @click="onCheckClicked()"
                v-bind:class="activeStatus"
              >
                <i class="cil-task"></i> {{ $t("common.check") }}
              </CButton>
              <!-- #endregion 確認ボタン -->

            </CForm>
          </CCardBody>

          <CCardFooter>
            <!-- #region 戻るボタン -->
            <CButton
              color="secondary"
              @click="onBackClicked()"
            ><i class="icon cil-arrow-left mr-2"></i>{{ $t("common.back") }}</CButton>
            <!-- #endregion 戻るボタン -->
          </CCardFooter>

        </CCard>
      </CCol>
    </CRow>
    <!-- #endregion Main -->

    <!-- #region Check -->
    <CModal
      color="info"
      size="lg"
      :closeOnBackdrop="false"
      :centered="true"
      :show.sync="checkModal"
    >
      <CForm class="submit_form">
        <!-- #region プランタイプ -->
        <CRow>
          <CCol class="label"><strong>{{ $t("planCreatePage.planType") }}</strong></CCol>
          <CCol><span> {{ edit.planType | planType }} </span></CCol>
        </CRow>
        <!-- #endregion プランタイプ -->

        <!-- #region 名称 -->
        <CRow>
          <CCol class="label"><strong>{{ $t("planCreatePage.name") }}</strong></CCol>
          <CCol><span> {{ edit.name }} </span></CCol>
        </CRow>
        <!-- #endregion 名称 -->
      </CForm>

      <template #header> {{ $t("common.check") }} </template>

      <template #footer>
        <CButton
          @click="checkModal = false"
          color="secondary"
        >
          <i class="icon cil-x"></i> {{ $t("common.cancel") }}
        </CButton>
        <CButton
          color="success"
          @click="onRegisterClicked()"
        >
          <i class="icon cil-save mr-1"></i> {{
          $t("common.register")
        }}
        </CButton>
      </template>
    </CModal>
    <!-- #endregion Check -->

    <!-- #region Modal -->
    <template>

      <SuccessModal
        :successModal="successModal"
        @close="successModal = $event"
      />

      <ErrorModal
        :errorModal="errorModal"
        :errorMessage="errorMessage"
        @close="errorModal = $event"
      />

    </template>
    <!-- #endregion Modal -->

  </div>
</template>

<script>
// import i18n from "../i18n";
import axios from "axios";
// import PLAN_TYPE from "@/mixins/property";
import PLAN_TYPE_SELECTION from "@/mixins/property";

import {
  required,
  // numeric,
  // minLength,
  // maxLength,
  // helpers,
} from "vuelidate/lib/validators";

export default {
  name: "PlanCreate",

  mixins: [PLAN_TYPE_SELECTION],

  components: {},

  computed: {
    activeStatus() {
      return this.$v.edit.$invalid ? 'inactive' : 'active'
    },

    uid() {
      return this.$store.state.uniqueId;
    },
  },

  watch: {
    successModal(newValue, oldValue) {
      // SuccessModalを閉じたら前画面に戻る
      if (!newValue && oldValue) this.backPage();
    },
  },

  data() {
    return {
      //#region Flag
      loading: false,
      checkModal: false,
      successModal: false,
      errorModal: false,
      //#endregion Flag


      //#region Edit
      edit: {
        planType: 1, // 1: 宿泊・人数売り, 2: 宿泊・部屋売り, 3: 日帰り・人数売り, 4: 日帰り・部屋売り
        name: "",
        description: "",
      },
      //#endregion Edit


      //#region Response data
      response: [],
      //#endregion Response data


      //#region Message
      errorMessage: "",
      //#endregion Message
    }
  },

  validations() {
    return {
      edit: {
        planType: { required },
        name: { required },
        description: {},
      }
    };
  },

  methods: {
    //#region Event
    /** 確認ボタン押下 */
    onCheckClicked() {
      this.$v.edit.$invalid ?
        this.$v.edit.$touch() :
        this.checkModal = true;
    },

    /** 登録ボタン押下 */
    onRegisterClicked() {
      this.postPlan();
    },

    /** 戻るボタン押下 */
    onBackClicked() {
      this.backPage();
    },
    //#endregion Event


    //#region Request
    /** プラン登録リクエスト */
    postPlan() {
      this.loading = true;

      // TODO: 
      this.edit.uniqueId = this.uid;

      const url = `/rest/hotel/${this.uid}/plan`;
      const body = this.edit;

      axios
        .post(url, body, {
          headers: {
            'Authorization': `Bearer ${this.$keycloak.token}`,
            'Accept-Language': this.$i18n.locale,
            // 'Accept-Language': `*`,
          },
        })
        .then((resp) => {
          console.log("resp.data: " + JSON.stringify(resp.data));
          this.loading = false;
          this.checkModal = false;
          this.successModal = true;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    //#endregion Request


    //#region Method
    backPage() {
      this.$router.go(-1);
    },
    //#endregion Method
  },

}
</script>
